<template>
  <main id="chips-tag-box" ref="mainBox">
    <div v-show="spinning" class="loading-box">
        <a-spin
          class="loading-child"
          tip="Loading..."
          :spinning="spinning"
          :delay="500"
        >
        </a-spin>
    </div>
    <div class="chips-content">
      <!-- 公开组 -->
      <article class="hot-group">
        <nav>
          <div class="home-back">
              <img class="back-img" src="../../../assets/img/storm/icon/homeBlack.svg" @click="backFun" width="22px">
              <img
              class="load-img"
              src="../../../assets/img/myMindIcon/right-arrow.svg"
              alt=""
              />
          </div>
          <div id="tag" class="nav-container">
            <div class="nav-box" v-for="(item,index) in stormTagType" :key="index">
              <div class="nav-item">
                <img :src="item.icon" :alt="item.name" width="44px">
                <div class="nav-label">{{item.name}}</div>
              </div>
            </div>
          </div>
        </nav>
        <section class="shared-group-box" v-if="hotGroupData.length>0">
          <div class="shared-group-title">
            <h2 class="hot-group-name">
              {{ getString(strings.Open_Group) }}
            </h2>
            <div class="see-more" @click="seeMoreGroups(getString(strings.Open_Group))">
              <span>{{ getString(strings.Mind_Group_See_More) }}</span>
              <a-icon type="right" />
            </div>
          </div>
          <div class="shared-group-container">
            <div class="shared-group-item" v-for="(item,index) in hotGroupData" :key="item.id+index" @click="goToGroupDetails(item.id)">
              <img class="shared-group-img" :src="httpImageToPrefix(item.cover)" :alt="item.name">
              <div class="shared-group-name" :title="item.name">
                {{item.name}}
              </div>
              <div class="shared-group-number">
                <span>{{item.memberCount}}{{getString(strings.Mind_Group_Number_Of_People)}}</span>
                <div class="dot"></div>
                <span>{{item.gmapCount}}{{getString(strings.Mind_Group_Individual)+getString(strings.Mind_Group_Mind_Mapping)}}</span>
              </div>
              <div class="shared-group-introduce" :title="item.desc">
                {{item.desc}}
              </div>
              <div class="add-button" @click.stop="setGroupType('hot')">
                <!-- 判断加入共享组是否需要付费加入或验证消息，如果需要则下方按钮为“申请加入”，不需要则为“立即加入；如果已加入，则显示已加入” -->
                <button class="join-now" v-if="((!item.isJoined) && (!item.needVerification) && (item.mbeansRequired == 0))"
                  @click="immediatelyToJoin(item.id,index)">
                  {{ getString(strings.Mind_Group_Immediately_To_Join) }}
                </button>
                <button class="join-now" v-else-if="!item.isJoined && item.mbeansRequired && item.mbeansRequired != 0"
                  @click="applyToJoin(item,1,index)">
                    <label class="cursor-pointer">{{ getString(strings.Mind_Group_Immediately_To_Join) }}</label>
                    <label class="cursor-pointer" style="margin-left: 6px;">{{ item.mbeansRequired }}</label>
                    <img src="../../../assets/img/user/member/mBean.svg" alt="" width="14px" style="margin:2px 0 0 3px;">
                </button>
                <button class="join-now" v-else-if="!item.isJoined && item.needVerification"
                  @click="applyToJoin(item,2)">
                  {{ getString(strings.Mind_Group_Apply_To_Join) }}
                </button>
                <button type="button" disabled class="joined" v-else>
                  {{ getString(strings.Mind_Group_Joined) }}
                </button>
              </div>
            </div>
          </div>
        </section>
      </article>
       <!-- 风暴 -->
      <article class="storm-box" v-if="chipsFeedsData.length>0">
        <div class="storm-title">
          <h2 class="storm-name">
            {{ getString(strings.Public_Header_Mind) }}
          </h2>
          <div class="see-more" @click="seeMoreCrisps()">
            <span>{{ getString(strings.Mind_Group_See_More) }}</span>
            <a-icon type="right" />
          </div>
        </div>
        <div class="storm-container">
          <div class="storm-item" v-for="item in chipsFeedsData" :key="item.id" @click="commentCrips(item.id)">
            <div class="storm-head">
              <div class="user-info">
                <img 
                  v-if="item.isMember"
                  class="member-crown"
                  src="../../../assets/img/groupImg/crown.svg"
                  />
                <img class="user-img" 
                  src="../../../../static/img/userData/avatar_yellow_man.png" 
                  v-real-img="httpImageToPrefix(item.userAvatar)"
                  :alt="item.username">
                <div class="user-name">
                  {{item.username}}
                </div>
              </div>
              <button class="payment-use" v-if="item.isUsable" >
                  <label class="cursor-pointer">{{ item.mbeansRequired }}</label>
                  <img src="../../../assets/img/storm/mbeans.svg" alt="" width="10px" style="margin:0 3px;">
                  <label class="cursor-pointer">{{ getString(strings.Mind_Edit_Use) }}</label>
              </button> 
            </div>
            <div class="chips-pc-box">
                  <div class="chips-pc-cover" >
                    <img :src="httpImageToPrefix(item.cover)" alt="" />
                    <!-- 是否存在音频内容 -->
                    <div class="chips-pc-audio" v-if="item.isAudio">
                      <img
                        src="../../../assets/img/storm/storm_commend_data_icon.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="chips-pc-text">
                    <div class="chips-pc-title">
                      <span >{{
                        item.title
                      }}</span>
                    </div>
                    <div class="chips-pc-text-content">
                      <span>
                        {{ item.subtitle }}
                      </span>
                    </div>
                    <div class="chips-pc-text-reason" v-if="item.reason">
                      <span> 驳回理由： {{ item.reason }} </span>
                    </div>
                    <div class="chips-pc-bottom">
                      <div class="chips-pc-style">
                        <span
                          v-for="(itemStyle, index) in item.tags"
                          :key="index"
                          @click.stop="stormToBookTag(itemStyle.id)"
                          >{{ itemStyle.name }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
          </div>

        </div>

      </article>
      <!-- 公开组 -->
      <article class="hot-group open-group"  v-if="openGroupData.length>0">
        <section class="shared-group-box">
          <div class="shared-group-title">
            <h2 class="hot-group-name">
              {{ getString(strings.Open_Group) }}
            </h2>
            <div class="see-more" @click="seeMoreGroups(getString(strings.Open_Group))">
              <span>{{ getString(strings.Mind_Group_See_More) }}</span>
              <a-icon type="right" />
            </div>
          </div>
          <div class="shared-group-container">
            <div class="shared-group-item" v-for="(item,index) in openGroupData" :key="item.id+index" @click="goToGroupDetails(item.id)">
              <img class="shared-group-img" :src="httpImageToPrefix(item.cover)" :alt="item.name">
              <div class="shared-group-name" :title="item.name">
                {{item.name}}
              </div>
              <div class="shared-group-number">
                <span>{{item.memberCount}}{{getString(strings.Mind_Group_Number_Of_People)}}</span>
                <div class="dot"></div>
                <span>{{item.gmapCount}}{{getString(strings.Mind_Group_Individual)+getString(strings.Mind_Group_Mind_Mapping)}}</span>
              </div>
              <div class="shared-group-introduce" :title="item.desc">
                {{item.desc}}
              </div>
              <div class="add-button" @click.stop="setGroupType('open')">
                <!-- 判断加入共享组是否需要付费加入或验证消息，如果需要则下方按钮为“申请加入”，不需要则为“立即加入；如果已加入，则显示已加入” -->
                <button class="join-now" v-if="((!item.isJoined) && (!item.needVerification) && (item.mbeansRequired == 0))"
                  @click="immediatelyToJoin(item.id,index)">
                  {{ getString(strings.Mind_Group_Immediately_To_Join) }}
                </button>
                <button class="join-now" v-else-if="!item.isJoined && item.mbeansRequired && item.mbeansRequired != 0"
                  @click="applyToJoin(item,1,index)">
                    <label class="cursor-pointer">{{ getString(strings.Mind_Group_Immediately_To_Join) }}</label>
                    <label class="cursor-pointer" style="margin-left: 6px;">{{ item.mbeansRequired }}</label>
                    <img src="../../../assets/img/user/member/mBean.svg" alt="" width="14px" style="margin:2px 0 0 3px;">
                </button>
                <button class="join-now" v-else-if="!item.isJoined && item.needVerification"
                  @click="applyToJoin(item,2)">
                  {{ getString(strings.Mind_Group_Apply_To_Join) }}
                </button>
                <button type="button" disabled class="joined" v-else>
                  {{ getString(strings.Mind_Group_Joined) }}
                </button>
              </div>
            </div>
          </div>
        </section>
      </article>
        <!-- 风暴 -->
      <article class="storm-box">
        <div class="storm-title">
          <h2 class="storm-name">
            {{ getString(strings.Public_Header_Mind) }}
          </h2>
          <div class="see-more" @click="seeMoreCrisps()">
            <span>{{ getString(strings.Mind_Group_See_More) }}</span>
            <a-icon type="right" />
          </div>
        </div>
        <div class="storm-container">
          <div class="storm-item" v-for="item in chipsFeedsData2" :key="item.id" @click="commentCrips(item.id)">
            <div class="storm-head">
              <div class="user-info">
                <img 
                  v-if="item.isMember"
                  class="member-crown"
                  src="../../../assets/img/groupImg/crown.svg"
                  />
                <img class="user-img" 
                  src="../../../../static/img/userData/avatar_yellow_man.png" 
                  v-real-img="httpImageToPrefix(item.userAvatar)"
                  :alt="item.username">
                <div class="user-name">
                  {{item.username}}
                </div>
              </div>
              <button class="payment-use" v-if="item.isUsable" >
                  <label class="cursor-pointer">{{ item.mbeansRequired }}</label>
                  <img src="../../../assets/img/storm/mbeans.svg" alt="" width="10px" style="margin:0 3px;">
                  <label class="cursor-pointer">{{ getString(strings.Mind_Edit_Use) }}</label>
              </button> 
            </div>
            <div class="chips-pc-box">
                  <div class="chips-pc-cover" >
                    <img :src="httpImageToPrefix(item.cover)" alt="" />
                    <!-- 是否存在音频内容 -->
                    <div class="chips-pc-audio" v-if="item.isAudio">
                      <img
                        src="../../../assets/img/storm/storm_commend_data_icon.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="chips-pc-text">
                    <div class="chips-pc-title">
                      <span >{{
                        item.title
                      }}</span>
                    </div>
                    <div class="chips-pc-text-content">
                      <span>
                        {{ item.subtitle }}
                      </span>
                    </div>
                    <div class="chips-pc-text-reason" v-if="item.reason">
                      <span> 驳回理由： {{ item.reason }} </span>
                    </div>
                    <div class="chips-pc-bottom">
                      <div class="chips-pc-style">
                        <span
                          v-for="(itemStyle, index) in item.tags"
                          :key="index"
                          @click.stop="stormToBookTag(itemStyle.id)"
                          >{{ itemStyle.name }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
          </div>
        </div>
      </article>
    <!-- 加载更多 -->
      <div v-for="(mitem,mindex) in loadMoreData" :key="mindex">  
          <!-- 公开组 -->
          <article class="hot-group open-group" v-if="mitem.groupFeeds.length>0">
          <section class="shared-group-box">
            <div class="shared-group-title">
              <h2 class="hot-group-name">
                {{ getString(strings.Open_Group) }}
              </h2>
              <div class="see-more" @click="seeMoreGroups(getString(strings.Open_Group))">
                <span>{{ getString(strings.Mind_Group_See_More) }}</span>
                <a-icon type="right" />
              </div>
            </div>
            <div class="shared-group-container">
              <div class="shared-group-item" v-for="(item,index) in mitem.groupFeeds" :key="item.id+index" @click="goToGroupDetails(item.id)">
                <img class="shared-group-img" :src="httpImageToPrefix(item.cover)" :alt="item.name">
                <div class="shared-group-name" :title="item.name">
                  {{item.name}}
                </div>
                <div class="shared-group-number">
                  <span>{{item.memberCount}}{{getString(strings.Mind_Group_Number_Of_People)}}</span>
                  <div class="dot"></div>
                  <span>{{item.gmapCount}}{{getString(strings.Mind_Group_Individual)+getString(strings.Mind_Group_Mind_Mapping)}}</span>
                </div>
                <div class="shared-group-introduce" :title="item.desc">
                  {{item.desc}}
                </div>
                <div class="add-button" @click.stop="setGroupType('moreGroup')">
                  <!-- 判断加入共享组是否需要付费加入或验证消息，如果需要则下方按钮为“申请加入”，不需要则为“立即加入；如果已加入，则显示已加入” -->
                  <button class="join-now" v-if="((!item.isJoined) && (!item.needVerification) && (item.mbeansRequired == 0))"
                    @click="immediatelyToJoin(item.id,index,mindex)">
                    {{ getString(strings.Mind_Group_Immediately_To_Join) }}
                  </button>
                  <button class="join-now" v-else-if="!item.isJoined && item.mbeansRequired && item.mbeansRequired != 0"
                    @click="applyToJoin(item,1,index,mindex)">
                    <label class="cursor-pointer">{{ getString(strings.Mind_Group_Immediately_To_Join) }}</label>
                    <label class="cursor-pointer" style="margin-left: 6px;">{{ item.mbeansRequired }}</label>
                    <img src="../../../assets/img/user/member/mBean.svg" alt="" width="14px" style="margin:2px 0 0 3px;">
                  </button>
                  <button class="join-now" v-else-if="!item.isJoined && item.needVerification"
                    @click="applyToJoin(item,2)">
                    {{ getString(strings.Mind_Group_Apply_To_Join) }}
                  </button>
                  <button type="button" disabled class="joined" v-else>
                    {{ getString(strings.Mind_Group_Joined) }}
                  </button>
                </div>
              </div>
            </div>
          </section>
        </article>
        <!-- 风暴 -->
        <article class="storm-box" v-if="mitem.crisps.length>0">
          <div class="storm-title">
            <h2 class="storm-name">
              {{ getString(strings.Public_Header_Mind) }}
            </h2>
            <div class="see-more" @click="seeMoreCrisps()">
              <span>{{ getString(strings.Mind_Group_See_More) }}</span>
              <a-icon type="right" />
            </div>
          </div>
          <div class="storm-container">
            <div class="storm-item" v-for="item in mitem.crisps" :key="item.id" @click="commentCrips(item.id)">
              <div class="storm-head">
                <div class="user-info">
                  <img 
                    v-if="item.isMember"
                    class="member-crown"
                    src="../../../assets/img/groupImg/crown.svg"
                    />
                  <img class="user-img" 
                    src="../../../../static/img/userData/avatar_yellow_man.png" 
                    v-real-img="httpImageToPrefix(item.userAvatar)"
                    :alt="item.username">
                  <div class="user-name">
                    {{item.username}}
                  </div>
                </div>
                <button class="payment-use" v-if="item.isUsable" >
                    <label class="cursor-pointer">{{ item.mbeansRequired }}</label>
                    <img src="../../../assets/img/storm/mbeans.svg" alt="" width="10px" style="margin:0 3px;">
                    <label class="cursor-pointer">{{ getString(strings.Mind_Edit_Use) }}</label>
                </button> 
              </div>
              <div class="chips-pc-box">
                    <div class="chips-pc-cover" >
                      <img :src="httpImageToPrefix(item.cover)" alt="" />
                      <!-- 是否存在音频内容 -->
                      <div class="chips-pc-audio" v-if="item.isAudio">
                        <img
                          src="../../../assets/img/storm/storm_commend_data_icon.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="chips-pc-text">
                      <div class="chips-pc-title">
                        <span >{{
                          item.title
                        }}</span>
                      </div>
                      <div class="chips-pc-text-content">
                        <span>
                          {{ item.subtitle }}
                        </span>
                      </div>
                      <div class="chips-pc-text-reason" v-if="item.reason">
                        <span> 驳回理由： {{ item.reason }} </span>
                      </div>
                      <div class="chips-pc-bottom">
                        <div class="chips-pc-style">
                          <span
                            v-for="(itemStyle, index) in item.tags"
                            :key="index"
                            @click.stop="stormToBookTag(itemStyle.id)"
                            >{{ itemStyle.name }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
            </div>
          </div>
        </article>
      </div>  
    </div>

    <footer>
      <div class="load-more" @click="loadMoreGroupData" v-if="loadStatus=='load'">
        {{getString(strings.Load_More)}}
      </div>
      <div class="loading" v-else-if="loadStatus=='loading'">
        {{getString(strings.Loading)}}
      </div> 
      <div class="it-over" v-else-if="loadStatus=='over'">
        {{getString(strings.It_Is_Over)}}~
      </div> 
    </footer>
    <!-- 申请加入共享组弹框 -->
    <ApplyJoinGroup 
      :tagModalShow="applyJoinModalShow"
      :groupItem="groupItem"
      @hideModal="hideModal"
      @applyJoinGroup="applyJoinGroup"/>  
  </main>
</template>

<script>
import {
 postCrispsGetTagPage
} from "../../../common/netWork/crisps_api";
import {postGroupJoin} from "../../../common/netWork/group_api";

import { mapMutations } from "vuex";
import strings from "../../../common/lang/strings";
import getString from "../../../common/lang/language";
import httpImageToPrefix from '../../../utils/httpImageToPrefix';
import ApplyJoinGroup from './ApplyJoinGroup';
import { getJwt } from "@/common/netWork/base";

if (
  typeof navigator === "object" &&
  typeof navigator.userAgent === "string" &&
  navigator.userAgent.indexOf("Electron") >= 0
) {
  var { ipcRenderer } = window.require("electron");
  // var ipcRenderer = window.ipcRenderer
}
let tagId = '';
var meMessage = null
let meMessageStr = localStorage.getItem("meMessage")
if (meMessageStr != null && meMessageStr.length > 0) {
  meMessage = JSON.parse(meMessageStr);
}
function ajaxPromise(callback,params,that){
  return new Promise(function (resolve, reject) {
    callback(
      params,
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
        that.$message.error(error.desc);
      }
    );
  });
}
export default {
  inject: ["reload"],
  components:{
    ApplyJoinGroup
  },
  data() {
    return {
      strings, //语言包
      spinning: false,
      nowElectron: false,
      lastId:'',
      stormTagType:[],//标签数据
      hotGroupData:[],//热门组数据
      openGroupData:[],//公开组数据
      groupType:'',//点击加入按钮的是热门组或公开组
      groupItem:{},//点击加入按钮的共享组数据
      applyJoinModalShow: false, //申请加入共享组弹框
      groupTagName: '', //申请理由
      chipsFeedsData:[],//风暴数据
      chipsFeedsData2:[],//风暴数据
      groupTag:'',
      crispsTag:'',
      loadMoreData:[],//加载更多数据
      loadMoreDataTime :null,
      loadMoreDataFlag: false, //是否可以请求列表数据
      lastIdHome:'',
      loadStatus:'load',
      colorList: [
        "#9db2c4",
        "#9179c3",
        "#e18995",
        "#ebc185",
        "#82d0dc",
        "#60a5a2",
        "#6d6a8c",
        "#f1cecc",
      ],
    };
  },
  props: {
    
  },
  mounted() {
    this.nowElectron = this.$tools.isElectron();
    tagId =  this.$route.query.id || '';
    this.getFirstData();
    this.getStormTagType();
    
  },
  methods: {
    ...mapMutations(["showLoginModel",]),
    //语言包
    getString(i) {
      return getString(i);
    },
    httpImageToPrefix(src) {
      return httpImageToPrefix(src)
    },
    backFun(){
      if (window.history.length > 1) {
        const stormBackRoute = JSON.parse(sessionStorage.getItem('stormBackRoute')) ;
        if (stormBackRoute) {
          if (this.$router.history.current.fullPath==stormBackRoute) {
            this.$router.push('/Home/Storm')
            return
          }
          this.$router.push(stormBackRoute)
        }
      } else {
        this.$router.push('/')
      }
    },
    //首页数据
    getFirstData(){
      this.spinning = true;
      const obj ={
        tagId,
        type:2,
        lastId:'',
      }
      this.hotGroupData=[];
      ajaxPromise(postCrispsGetTagPage,obj,this)
        .then((res) => {
         if (!!res) {
            let groupFeeds = res.groupFeeds 
            if (!!groupFeeds && groupFeeds.length>0) {
              let lastItem = groupFeeds[groupFeeds.length - 1]
              if (lastItem) {
                this.lastId = lastItem.id
                this.getOpenGroupData()
              }
              this.hotGroupData = groupFeeds
              // let len = this.hotGroupData.length;
              // if (len > 4) {
              //   this.hotGroupData.length = 4
              // }
            }
            const crisps = res.crispsFeeds;
            if (!!crisps && crisps.length>0) {
              this.chipsFeedsData = crisps;
              // let len = this.chipsFeedsData.length;
              // if (len > 4) {
              //   this.chipsFeedsData.length = 4 
              // }
            } 
            this.crispsTag = res.crispsTag 
            this.crispsTag = res.crispsTag 
         }
        })
        .finally(() => {
          this.spinning = false;
          // console.log(this.hotGroupData);
        });
    },
    // 第二次请求数据
    getOpenGroupData(){
      const obj ={
        tagId,
        type:2,
        lastId:this.lastId,
      }
      // console.log(obj);
      this.openGroupData=[];
      ajaxPromise(postCrispsGetTagPage,obj,this)
        .then((res) => {
         if (!!res) {
            let groupFeeds = res.groupFeeds 
            if (!!groupFeeds && groupFeeds.length>0) {
              // let len = groupFeeds.length;
              // if (len > 4) {
              //   groupFeeds.length = 4
              // }
              this.openGroupData = groupFeeds
            }
            const crisps = res.crispsFeeds;
            if (!!crisps && crisps.length>0) {
              let lastItem = crisps[crisps.length - 1]
              if (lastItem) {
                this.lastIdHome = lastItem.id
                this.loadMoreDataFlag = true;
                this.loadMoreDataFun();
              }
              // let len = crisps.length;
              // if (len > 4) {
              //   crisps.length = 4 
              // }
              this.chipsFeedsData2 = crisps;
            } 
         }
        })
    },
    loadMoreDataFun() {
      this.$nextTick(() => {
        let box = this.$refs.mainBox;
        if (box) {
          const onMouseWheel = (e) => {
              clearTimeout(this.loadMoreDataTime);
              this.loadMoreDataTime = setTimeout(() => {
                let ev = e || window.event;
                let down = true; // 定义一个标志，当滚轮向下滚时，执行一些操作
                down = ev.wheelDelta ? ev.wheelDelta < 0 : ev.detail > 0;
                if (down) {
                  this.loadMoreGroupData();
                }
              }, 100);
          }
          box.removeEventListener("mousewheel",onMouseWheel,true);
          box.addEventListener("mousewheel",onMouseWheel,true);
        }
      })
    },
    //加载更多数据
    loadMoreGroupData(){
      if (this.loadMoreDataFlag) {
        this.loadStatus='loading'
          const obj ={
            tagId,
            type:2,
            lastId:this.lastIdHome,
          }
          ajaxPromise(postCrispsGetTagPage,obj,this)
            .then((res) => {
             if (!!res) {
                let groupFeeds = res.groupFeeds || []; 
                if (!!groupFeeds && groupFeeds.length>0) {
                    this.loadMoreDataFlag = true;
                    this.loadStatus='load'
                }
                const crisps = res.crispsFeeds || []; 
                if (!!crisps && crisps.length>0) {
                    let lastItem = crisps[crisps.length - 1]
                    if (lastItem) {
                        this.lastIdHome = lastItem.id
                    }
                    this.loadMoreDataFlag = true;
                    this.loadStatus='load'
                }
                this.loadMoreData.push({
                  groupFeeds,
                  crisps
                })
                if ((!groupFeeds && !crisps) && (groupFeeds.length==0 && crisps.length==0)){
                    this.loadMoreDataFlag = false;
                    this.loadStatus='over'
                }
             }
            })
           
      }
    },
    // 获取标签数据
    getStormTagType(lastId){
      let stormTag = JSON.parse(sessionStorage.getItem('stormTag'));
      if (stormTag) {
        this.stormTagType[0]=stormTag
      }
    },
     //添加背景颜色
    addBackgroundColor(array) {
      if (array.length > this.colorList.length) {
        for (let index = 0; index < array.length; index++) {
          if (array.length != this.colorList.length) {
            this.colorList.push(this.colorList[index]);
          }
        }
      }
    },
    setGroupType(type){
      this.groupType = type
    },
    //请求加入群组接口
    joinTheGroup(id,type,index,mindex) {
      if (getJwt()) {
        this.spinning = true;
        const obj ={
          groupId: id,
          message: this.groupTagName
        }
         postGroupJoin(obj,
          (res) => {
            this.spinning = false;
            if (type==0) {
              // this.getFirstData();
              this.$message.success(getString(strings.Mind_Group_Join_Successfully));
              if (this.groupType == 'hot') {
                this.hotGroupData[index].isJoined = true
              }else if (this.groupType == 'open'){
                this.openGroupData[index].isJoined = true
              }else{//moreGroup
                this.loadMoreData[mindex].groupFeeds[index].isJoined = true
              }
            }else if (type==1) {
              this.$message.success(getString(strings.Mind_Group_Join_Successfully));
              if (this.groupType == 'hot') {
                this.hotGroupData[index].isJoined = true
              }else if (this.groupType == 'open'){
                this.openGroupData[index].isJoined = true
              }else{//moreGroup
                this.loadMoreData[mindex].groupFeeds[index].isJoined = true
              }
            }else if (type==2) {
              this.$message.success(getString(strings.Mind_Group_Application_Sent_Successfully));
            }
            this.hotGroupData = JSON.parse(JSON.stringify(this.hotGroupData))
            this.openGroupData = JSON.parse(JSON.stringify(this.openGroupData))

          },
          (error) => {
            this.spinning = false;
            if (type==1) {
              if (error.code==152) {//M豆数量不足
                let that = this;
                this.$confirm({
                  title: getString(strings.Mind_Tips),
                  content: getString(strings.Mind_Your_M_Bean_Insufficient_Whether_Go__Purchase_M_Bean),
                  centered: true,
                  onOk() {
                    that.bus.$emit('shoppingModel',true);
                  },
                  onCancel() {},
                });
                return
              }
             
            }
            this.$message.error(error.desc);
          });

      } else {
        this.showLoginModel(true);
      }
    },
    //立即加入
    immediatelyToJoin(id,index,mindex) {
      this.joinTheGroup(id,0,index,mindex);
    },
    // 显示加入共享组弹框
    applyToJoin(item,type,index,mindex) {
      this.groupItem = item;
      if (getJwt()) {
        if (type==1) {
          let that = this;
          this.$confirm({
            title: getString(strings.Mind_Tips),
            content: getString(strings.Mind_Shooping_Confirm_Payment)+item.mbeansRequired+getString(strings.Mind_MBean),
            centered: true,
            onOk() {
              // console.log(item);
              that.groupTagName = '';
              that.joinTheGroup(that.groupItem.id,type,index,mindex);
            },
            onCancel() {},
          });
        }else{
          this.applyJoinModalShow = true
        }
      } else {
        this.showLoginModel(true);
      }
    },
    // 隐藏加入共享组弹框
    hideModal(type){
      if (type==1) {
          
        }else{
          this.applyJoinModalShow = false
        }
    },
    //申请加入
    applyJoinGroup(application){
      this.groupTagName = application;
      this.joinTheGroup(this.groupItem.id,2);
    },
    goToGroupDetails(id){
      if (this.nowElectron) {
        let url = "/g/" + id;
        this.openMind(url);
      } else {
        let newWindow = window.open();
        const routerData = this.$router.resolve({
          path: "/g/" + id,
        });
        newWindow.location = routerData.href;
      }
    },
    // getChipsFeedsData(){
    //   ajaxPromise(postCrispsPullFeed,null,this)
    //     .then((res) => {
    //       const crispsFeeds = res.crispsFeeds;
    //       if (!!crispsFeeds && crispsFeeds.length>0) {
    //         crispsFeeds[0].m=5
    //         this.chipsFeedsData = crispsFeeds;
    //         this.chipsFeedsData.length = 4 
    //       } 
          
    //     })
    // },
    commentCrips(id) {
      if (this.nowElectron) {
        let url = "/c/" + id;
        this.openMind(url, { type: 'storm' });
      } else {
        let newWindow = window.open();
        const routerData = this.$router.resolve({
          path: "/c/" + id,
        });
        newWindow.location = routerData.href;
      }
    },
    openMind(item,type) {
      //打开导图
      ipcRenderer.send("openCalendarWindow", item, type);
    },
    //标签跳转订阅
    stormToBookTag(tag) {
      this.seeMoreCrisps(tag)
    },
    // 设置返回按钮路由
    setStormBackRoute(){
      sessionStorage.setItem('stormBackRoute',JSON.stringify(this.$router.history.current.fullPath));
    },
    seeMoreGroups(type){
      this.setStormBackRoute();
      let tag = tagId;
      this.$router.push({
        path: "/Home/moreGroups",
        name: 'moreGroups',
        query: {
          tag,
          type
        },
      })
    },
    seeMoreCrisps(tag=tagId){
      this.setStormBackRoute();
      this.$router.push({
        path: "/Home/moreCrisps",
        name: 'moreCrisps',
        query: {
          tag,
        },
      })
    },
  },
  destroyed() {
  },
  watch: {
     
  },
};
</script>

<style lang="less" scoped>

#chips-tag-box {
  width: 100%;
  height: inherit;
  background-color: #F5F5F5;
  margin: 0 auto;
  padding-bottom: 30px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 6px;
    height: 12px;
  }
  &::-webkit-scrollbar-track {
    background: rgb(239, 239, 239);
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: #bfbfbf;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #bfbfbf;
  }
  /* 加载动画开始 */
.loading-box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .1);
  z-index: 300;
}
.loading-child {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(2);
}
.ant-spin-dot-item {
  background-color: #fd492b;
}
.ant-spin {
  color: #fd492b;
}
.ant-spin .ant-spin-dot-item {
  background-color: #fd492b !important;
}
/* 加载动画结束 */
  .chips-content{
    max-width: 1200px;
    min-width: 1200px;
    margin: 0 auto ;
    article{
      width: 100%;
      background: #FFFFFF;
      padding: 20px;
    }
    .hot-group{
      nav{
        width: 100%;
        display: flex;
        align-items: center;
        overflow: hidden;
        .home-back{
              display: flex;
              align-items: center;
              .back-img{
                cursor: pointer;
              }
              .load-img {
                margin: 0 8px;
                margin-top: 2px;
                width: 16px;
                height: 16px;
                display: block;
            }
          }
        .nav-container{
            white-space: nowrap;
            overflow: auto;
            margin-top: 20px;
            &::-webkit-scrollbar {
              // visibility: hidden;
              height: 0;
            }
          .nav-box{
            display: inline-block;
            &:nth-child(n+2){
              margin-left: 80px;
            }
          }
        }
        .nav-item{
          min-width: 44px;
          display: flex;
          justify-content: center;
          align-items: center;
          .nav-label{
            font-family: PingFang SC;   
            font-weight: 500;       
            color: #333333; 
            font-size: 14px;
            margin-top: 5px;
            margin-left: 10px;
          }
        }
      }
      .shared-group-box{
        width: 100%;
        margin-top: 20px;
        .shared-group-title{
          width: 100%;
          display: flex;
          justify-content: space-between;
          .hot-group-name{
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            margin: 0;
          }
          .see-more{
            font-size: 12px;
            color: #666666;
            cursor: pointer;
            display: flex;
            align-items: center;
            &:hover{
              color: #333;
            }
            span{
              margin-right: 8px;
            }
          }
        }
        .shared-group-container{
          width: 100%;
          margin-top: 20px;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          .shared-group-item{
            width: 275px;
            padding: 20px;
            margin: 20px 15px 0 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: #FFFFFF;
            box-shadow: 0px 0px 6px rgba(78, 57, 57, 0.1);
            cursor: pointer;
            &:hover{
              box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
            }
            
            border-radius: 4px;
            .shared-group-img{
              width: 84px;
              height: 84px;
              border-radius: 8px;
            }
            .shared-group-name{
              width: 100%;      
              text-align: center;        
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #333333;
              margin-top: 20px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .shared-group-number{
              font-size: 12px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #999999;
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 5px;

              .dot{
                width: 4px;
                height: 4px;
                background: #999999;
                border-radius: 50%;
                margin: 0 4px;
              }
            }
            .shared-group-introduce{
              width: 235px;
              height: 42px;
              text-align: center;
              margin-top: 20px;
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #666666;
              // white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              display:-webkit-box;
              -webkit-line-clamp:2;
              -webkit-box-orient:vertical;
            }
            .add-button{
              margin-top: 24px;
              padding: 0 8px;
              min-width: 124px;
              height: 36px;
              // outline: none;
              font-size: 14px;
              .join-now {
                width: 100%;
                height: 100%;
                color: #333;
                background: #FFFFFF;
                border: 1px solid #FD492B;
                cursor: pointer;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                &:hover{
                  color: #fff;
                  background: #FD492B;
                }
              }
              .joined{ 
                width: 100%;
                height: 100%;
                border:0;
                background-color: #E8E8E8;
                color: #666666;
                border-radius: 4px;

              }
            }
          }
        }
      }
    }
    .open-group{
      margin-top: 20px;
    }
    .storm-box{
      width: 100%;
      margin-top: 20px;
      .storm-title{
        width: 100%;
        display: flex;
        justify-content: space-between;
        .storm-name{
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
          margin: 0;
        }
        .see-more{
          font-size: 12px;
          color: #666666;
          cursor: pointer;
          display: flex;
          align-items: center;
          &:hover{
            color: #333;
          }
          span{
            margin-right: 8px;
          }
        }
      }
      .storm-container{
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          .storm-item{
            width: 572px;
            margin-top: 20px;
            padding: 20px;
            border: 1px solid #EEEEEE;
            border-radius: 8px;
            cursor: pointer;
            &:hover{
              box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
            }
            &:hover .chips-pc-box .chips-pc-text .chips-pc-title{
              color: #FD492B;
            }
            
            .storm-head{
              width: 100%;
              display: flex;
              justify-content: space-between;
              .user-info{
                display: flex;
                align-items: center;
                position: relative;
                .member-crown{
                  position: absolute;
                  left: 0;
                  top: -12px;
                }
                .user-img{
                  width: 24px;
                  height: 24px;
                  background: rgba(0, 0, 0, 0);
                  border-radius: 50%;
                  position: relative;
                }
                .user-name{
                  margin-left: 12px;
                  font-size: 12px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #999999;
                }
              }
              .payment-use{
                // width: 60px;
                height: 24px;
                padding: 0 8px;
                font-size: 12px;
                color: #333;
                background: #FFFFFF;
                border: 1px solid #EEEEEE;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
               
              }
            }
            .chips-pc-box {
              display: flex;
              margin-top: 12px;
              position: relative;
              .chips-pc-cover {
                width: 155px;
                height: 126px;
                min-width: 155px;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                background: rgba(0, 0, 0, 0);
                opacity: 1;
                border-radius: 10px;
                border: 1px solid #d6d6d6;
                
                cursor: pointer;
                .chips-pc-audio {
                  position: absolute;
                  left: 105px;
                  bottom: -2px;
                  img {
                    width: 45px;
                    height: 39px;
                  }
                }
              }
              .chips-pc-cover img {
                transition: 0.3s;
                cursor: pointer;
                height: 124px;
              }
              .chips-pc-cover img:hover {
                transform: scale(1.1);
                transition: 0.3s;
                cursor: pointer;
              }
              .chips-pc-text {
                width: 100%;
                margin-left: 20px;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                .chips-pc-title {
                  height: 22px;
                  line-height: 22px;
                  font-size: 16px;
                  /*font-weight: bold;*/
                  color: #333333;
                  opacity: 1;
                  span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    cursor: pointer;
                    transition: 0.3s;
                  }
                }
                .chips-pc-text-content {
                  width: 100%;
                  height: 44px;
                  font-size: 12px;
                  font-weight: 400;
                  color: #999;
                  opacity: 1;
                  margin-top: 8px;
                  span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    transition: 0.3s;
                    cursor: pointer;
                  }
                  span:hover {
                    color: #252525;
                    transition: 0.3s;
                  }
                }
                .chips-pc-text-reason {
                  font-size: 12px;
                  font-weight: 400;
                  color: #fd492b;
                }
                .chips-pc-bottom {
                  width: 100%;
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 20px;
                  color: #999999;
                  opacity: 1;
                  display: flex;
                  position: relative;
                  align-items: center;
                  .chips-pc-style {
                    display: flex;
                    flex-wrap: wrap;
                    span {
                      margin-top: 5px;
                      padding: 2px 12px;
                      margin-right: 20px;
                      background: #EEEEEE;
                      border-radius: 2px;
                      cursor: pointer;
                      &:hover{
                        color: #fff;
                        background: #FD492B;
                      }
                    }
                  }
                }
              }
            }
          }
      }    
    }    
  }
}
footer{
  display: flex;
  .load-more{
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 22px;
    color: #333333;
    margin: 20px auto;
    cursor: pointer;
  }
  .loading,.it-over{
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 22px;
    color: #666666;
    margin: 20px auto;
  
  }
} 
</style>
